const Download = () => import("@/views/auth/Download.vue");
const Login = () => import("@/views/auth/Login.vue");
const Register = () => import("@/views/auth/Register.vue");
const ForgotPassword = () => import("@/views/auth/ForgotPassword.vue");
const ResetPassword = () => import("@/views/auth/ResetPassword.vue");

export default [
    {
        name: "download",
        path: "/download",
        component: Download,
        hidden: true,
        meta: {
            middleware: "guest",
            title: `Download`,
        },
    },
    {
        name: "login",
        path: "/login",
        component: Login,
        hidden: true,
        meta: {
            middleware: "guest",
            title: `Login`,
        },
    },
    {
        name: "register",
        path: "/register",
        component: Register,
        hidden: true,
        meta: {
            middleware: "guest",
            title: `Register New Account`,
        },
    },
    {
        name: "forgot-password",
        path: "/forgot-password",
        component: ForgotPassword,
        hidden: true,
        meta: {
            middleware: "guest",
            title: `Forgot Password`,
        },
    },
    {
        name: "reset-password",
        path: "/reset-password",
        component: ResetPassword,
        hidden: true,
        meta: {
            middleware: "guest",
            title: `Reset Password`,
        },
    },
];
